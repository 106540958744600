export interface NewsItem {
    id: number;
    title: string;
    date: string;
    content: string;
}

export interface DbToolsItem {
    id: number;
    title: string;
    summary: string;
    type: 'database' | 'tool';
    jnode: boolean;
    group: string;
    person: string;
    email: string;
    url: string;
    image0: string;
    image1: string;
}

export const fixDbToolsUrl = (url: string): string => {
    let ret = url.replace(/^https?:\/\/bsi-ni\.brain\.riken\.jp/, '');
    ret = ret.replace(/^\/modules\/page\/index\.php\?content_id=/, '/page/');
    return ret;
}