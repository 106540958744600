import React from 'react';
import { Helmet } from 'react-helmet';
import XoopsCode from '../common/lib/XoopsCode';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import newsJson from './assets/news.json';
import { NewsItem } from './CustomUtil';

interface Props {
    lang: MultiLang;
}

const News: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]News[/en][ja]ニュース[/ja]', lang);
    return <div className="news">
        <Helmet><title>{title} - {Config.SITE_TITLE}</title></Helmet>
        <section>
            <h2>{title}</h2>
            {newsJson.map((item) => {
                const article = item as NewsItem;
                const key = `news${article.id}`;
                return <section key={key}>
                    <h3 id={key}>{Functions.mlang(article.title, lang)}</h3>
                    <div className="date">{article.date}</div>
                    <div className="contents">
                        <XoopsCode lang={lang} text={article.content} dohtml={true} />
                    </div>
                </section>;
            })}
        </section>
    </div>;
}

export default News;