const SITE_TITLE = 'BSI-Neuroinformatics';
const SITE_SLOGAN = 'RIKEN BSI Research Database Portal';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-25489807-3';
const XOONIPS_ITEMTYPES = ['paper', 'book', 'data'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
}

export default Config;
