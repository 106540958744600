import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../config';
import Functions from '../functions';
import newsJson from './assets/news.json';
import { NewsItem } from './CustomUtil';

interface Props {
    lang: MultiLang;
}

const message = {
    en: 'Databases and tools created by RIKEN BSI laboratories in BSI-NI Project(FY2007-FY2017) are available from this website. Since FY2018, this website is managed by Neuroinformatics Unit, RIKEN Center for Brain Science. We hope this site helps to promote further development of brain research.',
    ja: 'このウェブサイトは、理化学研究所 脳科学総合研究センター(1997年度-2017年度)のBSI-NIプロジェクト(2007年度-2017年度)にて構築されたBSI研究室のデーターベース・ツールを公開しています。本サイトは2018年度より理化学研究所 脳神経科学研究センター 神経情報基盤開発ユニットが運用を担当し、コンテンツ公開を継続して行っています。これらのデータベース・ツールのご利用が神経科学の発展に役立つことを期待しています。'
};

const WhatsNew: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    let items: NewsItem[] = [];
    for (let i = 0; i < 3; i++) {
        items.push(newsJson[i] as NewsItem);
    }
    return <div className="block">
        <section>
            <NoticeSiteHasBeenArchived lang={lang} />
            <p>{message[lang]}</p>
        </section>
        <hr />
        <section className="whatsnew">
            <div className="title">
                <h3>{Functions.mlang('[en]What\'s new[/en][ja]最新ニュース[/ja]', lang)}</h3>
                <p className="more"><Link to="/news">More ...</Link></p>
            </div>
            <div className="news">
                <ul>
                    {items.map((item) => <li key={item.id}><span className="date">{item.date}</span><HashLink to={`/news#news${item.id}`} >{Functions.mlang(item.title, lang)}</HashLink></li>)}
                </ul>
            </div>
        </section>
    </div>;
}

export default WhatsNew;