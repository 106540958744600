import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import About from '../custom/About';
import Contact from '../custom/Contact';
import DbTools from '../custom/DbTools';
import DbToolsTop from '../custom/DbToolsTop';
import News from '../custom/News';
import Page from '../custom/Page';
import Publication from '../custom/Publication';
import WhatsNew from '../custom/WhatsNew';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import PageNotFound from './lib/PageNotFound';
import XoopsPathRedirect from './XoopsPathRedirect';

interface Props {
    lang: MultiLang;
}

const MainContent: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    return (
        <div className="mainContent">
            <Switch>
                <Route exact path="/" render={() => <WhatsNew lang={lang} />} />
            </Switch>
            <div className="block">
                <Switch>
                    <Route exact path="/" render={() => <DbToolsTop lang={lang} />} />
                    <Route path="/dbtools" render={() => <DbTools lang={lang} />} />
                    <Route path="/news" render={() => <News lang={lang} />} />
                    <Route path="/about" render={() => <About lang={lang} />} />
                    <Route path="/publication" render={() => <Publication lang={lang} />} />
                    <Route path="/contact" render={() => <Contact lang={lang} />} />
                    <Route path='/database' render={() => <Database lang={lang} />} />
                    <Route path='/page/:id' render={(props: RouteComponentProps<{ id: string }>) => {
                        const {match} = props;
                        const id = match.params.id === '6' ? 6 : (match.params.id === '9' ? 9 : 0);
                        if (id === 0) {
                            return <PageNotFound lang={lang} />
                        }
                        return <Page lang={lang} id={id} />;
                    }} />
                    <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                    <Route component={XoopsPathRedirect} />
                </Switch>
            </div>
        </div>
    );
}

export default MainContent;