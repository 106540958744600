import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

const Header: React.FC<Props> = (props: Props) => {
    const { lang, location } = props;
    const params = new URLSearchParams(location.search);
    const nextLang = lang === 'en' ? 'ja' : 'en';
    const nextLangTitle = lang === 'en' ? '日本語' : 'English';
    params.set('ml_lang', nextLang);
    const nextLangUrl = location.pathname + '?' + params.toString();
    const links = [
        { title: 'Home', link: '/' },
        { title: '[en]Databases & Tools[/en][ja]データベース・ツール[/ja]', link: '/dbtools' },
        { title: '[en]News[/en][ja]ニュース[/ja]', link: '/news' },
        { title: '[en]About BSI-NI[/en][ja]プロジェクト概要[/ja]', link: '/about' },
        { title: '[en]Related Publications[/en][ja]関連論文[/ja]', link: '/publication' },
        { title: '[en]Contact[/en][ja]お問い合わせ先[/ja]', link: '/contact' },
    ]

    return (
        <header id="header">
            <div className="main-visual">
                <Link className="title-link" to="/"><span className="hidden">{Config.SITE_TITLE}</span></Link>
            </div>
            <div className="nav">
                <ul className="global-nav">
                    {links.map((item) => {
                        return <li key={item.title}><Link to={item.link}>{Functions.mlang(item.title, lang)}</Link></li>
                    })}
                </ul>
                <div className="lang-nav"><Link className={`lang-nav-${nextLang}`} to={nextLangUrl}><span className="hidden">{nextLangTitle}</span></Link></div>
            </div>
        </header>
    );
}

export default withRouter(Header);