import React from 'react';
import { Helmet } from 'react-helmet';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const Contact: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Contact[/en][ja]お問い合わせ先[/ja]', lang);
    return <div className="contact">
        <Helmet><title>{title} - {Config.SITE_TITLE}</title></Helmet>
        <section>
            <h2>{title}</h2>
            <p className="division">{Functions.mlang('[en]Neuroinformatics Unit, Integrative Computational Brain Science Collaboration Center, RIKEN Center for Brain Science[/en][ja]理化学研究所 脳神経科学研究センター 統合計算脳科学連携部門 神経情報基盤開発ユニット[/ja]', lang)}</p>
            <p className="address">email: cbs-is(at)ml.riken.jp</p>
        </section>
        <section>
            <h2>{Functions.mlang('[en]Request[/en][ja]お願い[/ja]', lang)}</h2>
            <p>{Functions.mlang('[en]If you use databases or tools provided through the BSI-NI project in a paper, we ask that you cite the source in the acknowledgements for the paper, as designated by each database or tool. Please confirm the details with each person in charge of the database.[/en][ja]このサイトのDBやツールを使用して論文を発表される場合は、それぞれのDB・ツールで指定している方法で謝辞に記載下さい。詳細は個々のDB担当者にご確認下さい。[/ja]', lang)}</p>
            <p>{Functions.mlang('[en]Also please let us know with the name and volume number of the publication, and the title of your paper.[/en][ja]また、記載掲載誌・掲載号・タイトルをBSI-NIプロジェクト事務局までお知らせ頂ければ幸いです。[/ja]', lang)}</p>
        </section>
    </div>;
}

export default Contact;