import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import XoopsCode from '../common/lib/XoopsCode';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import dbtoolsJson from './assets/dbtools.json';
import { DbToolsItem, fixDbToolsUrl } from './CustomUtil';

interface DbToolProps {
    lang: MultiLang;
    item: DbToolsItem;
}

const DbTool: React.FC<DbToolProps> = (props: DbToolProps) => {
    const { lang, item } = props;
    const title = Functions.mlang(item.title, lang);
    return <div id={`jump${item.id}`}>
        <h5>{title}</h5>
        <div className="item">
            <div className="icon">
                <img src={item.image0} alt={title} />
            </div>
            <div className="info">
                <div className="summary">
                    &lt;{Functions.mlang('[en]Summary[/en][ja]概要[/ja]', lang)}&gt;
                    <div><XoopsCode lang={lang} text={item.summary} dohtml={true} /></div>
                </div>
                <div className="contact">
                    &lt;{Functions.mlang('[en]Contact Information[/en][ja]問い合わせ先[/ja]', lang)}&gt;
                    <div>
                        {Functions.mlang(item.group, lang)}
                        {item.person !== '' && <> ({Functions.mlang(item.person, lang)} : <em>{item.email}</em>)</>}
                    </div>
                </div>
                <div className="url">
                    &lt;URL&gt;
                   <div>
                        {item.url !== '' ? <a href={fixDbToolsUrl(item.url)} target="_blank" rel="noopener noreferrer">{item.url}</a> : <>(unreleased)</>}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

interface Props {
    lang: MultiLang;
}

const DbTools: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const dbtools = dbtoolsJson as DbToolsItem[];
    const databases = dbtools.filter((dbtool) => { return dbtool.type === 'database'; });
    const tools = dbtools.filter((dbtool) => { return dbtool.type === 'tool'; });
    const title = Functions.mlang('[en]Databases & Tools[/en][ja]データベース・ツール[/ja]', lang);
    const titleDatabases = Functions.mlang('[en]Databases[/en][ja]データベース[/ja]', lang);
    const titleTools = Functions.mlang('[en]Tools[/en][ja]ツール[/ja]', lang);
    return <div className="dbtools">
        <Helmet><title>{title} - {Config.SITE_TITLE}</title></Helmet>
        <section className="index">
            <h2>{title}</h2>
            <div className="types">
                <section className="databases">
                    <h3>{titleDatabases}</h3>
                    <ul>
                        {databases.map(item => <li key={item.id}><HashLink to={`#jump${item.id}`}>{Functions.mlang(item.title, lang)}</HashLink>{item.jnode && <span className="incf-japan-node"> *</span>}</li>)}
                    </ul>
                </section>
                <section className="tools">
                    <h3>{titleTools}</h3>
                    <ul>
                        {tools.map(item => <li key={item.id}><HashLink to={`#jump${item.id}`}>{Functions.mlang(item.title, lang)}</HashLink>{item.jnode && <span className="incf-japan-node"> *</span>}</li>)}
                    </ul>
                </section>
            </div>
            <p className="legend">INCF Japan Node Platform</p>
        </section>
        <section className="detail">
            <h3>{Functions.mlang('[en]Ongoing assignment and its progress[/en][ja]各継続課題とその進捗について[/ja]', lang)}</h3>
            <section>
                <h4>{titleDatabases}</h4>
                <ul>
                    {databases.map(item => <li key={item.id}><DbTool lang={lang} item={item} /></li>)}
                </ul>
            </section>
            <section>
                <h4>{titleTools}</h4>
                <ul>
                    {tools.map(item => <li key={item.id}><DbTool lang={lang} item={item} /></li>)}
                </ul>
            </section>
        </section>
    </div >;
}

export default DbTools;