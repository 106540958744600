import React from 'react';
import { Helmet } from 'react-helmet';
import Config, { MultiLang } from '../config';
import styles from '../database/Database.module.css';
import DatabaseListItem from '../database/lib/DatabaseListItem';
import ItemUtil, { SearchCallbackFunc, SortCondition } from '../database/lib/ItemUtil';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
    id: 6 | 9
}

const pageDetails = {
    6: {
        title: 'EMOTIONS CLASSIFICATION USING EEG DATA',
        image: '/uploads/page/imagefile_0_9d7fd0b992ca91d7.gif',
        group: '[en]Lab. for Advanced Brain Signal Processing[/en][ja]脳信号処理研究チーム[/ja]',
        person: 'Andrzej Cichocki, Stefano Valenzi',
        email: 'a.cichocki(at)riken.jp, stefano(at)brain.riken.jp',
        summary: '',
        indexId: 17,
    },
    9: {
        title: 'Laboratory for Mathematical Neuroscience',
        image: '/uploads/page/imagefile_0_8ab5abd4c048e179.png',
        group: '[en]Laboratory for Mathematical Neuroscience[/en][ja]脳数理研究チーム[/ja]',
        person: '',
        email: '',
        summary: '[en]Mathematical neuroscience searches for fundamental principles of information processing by using simples models of neural networks through mathematical analysis, although the brain has found the same principles through a long history of evolution. The current project is a historical one, integrating published and unpublished papers towards establishment of mathematical neuroscience.[/en][ja]数理脳科学は、脳が長い進化の歴史の中で見出した脳型情報処理の基本原理を、簡単なモデルを用いて数理の力で見つけようとする。このプロジェクトは、数理脳科学の確立に向けた努力を文献を中心にまとめた歴史的な資料である。[/ja]',
        indexId: 39,
    }
}


class Page extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.searchFunc = this.searchFunc.bind(this);
    }

    searchFunc(condition: SortCondition, func: SearchCallbackFunc) {
        const { id: type } = this.props;
        const detail = pageDetails[type];
        ItemUtil.getListByIndexId(detail.indexId, condition, func);
    }

    render() {
        const { lang, id: type } = this.props;
        const detail = pageDetails[type];
        return <div className="page">
            <Helmet><title>{detail.title} - {Config.SITE_TITLE}</title></Helmet>
            <section>
                <h2>{detail.title}</h2>
                <p className="logo"><img src={detail.image} alt={detail.title} /></p>
                {detail.summary && <p className="summary">{Functions.mlang(detail.summary, lang)}</p>}
                <div className={styles.database}>
                    <DatabaseListItem lang={lang} url={`/page/${type}`} search={this.searchFunc} />
                </div>
                <p className="contact">
                    &lt;{Functions.mlang('[en]Contact Information[/en][ja]問い合わせ先[/ja]', lang)}&gt;
                    <div>
                        {Functions.mlang(detail.group, lang)}
                        {detail.person !== '' && <> ({Functions.mlang(detail.person, lang)} : <em>{detail.email}</em>)</>}
                    </div>
                </p>
            </section>
        </div>;
    }
}

export default Page;