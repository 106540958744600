import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { MultiLang } from '../config';
import Functions from '../functions';
import dbtoolsJson from './assets/dbtools.json';
import { DbToolsItem, fixDbToolsUrl } from './CustomUtil';

interface Props {
    lang: MultiLang;
}

const DbToolsTop: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const dbtools = dbtoolsJson as DbToolsItem[];
    const more = Functions.mlang('[en]Detail[/en][ja]概要へ[/ja]', lang);
    return <div className="dbtools-top">
        <ul>
            {dbtools.map(item => {
                const title = Functions.mlang(item.title, lang);
                const link = item.url === '' ? title : <a href={fixDbToolsUrl(item.url)} target="_blank" rel="noopener noreferrer">{title}</a>;
                return <li key={item.id}>
                    <div className="icon">
                        <img src={item.image1} alt={title} />
                    </div>
                    <div className="info">
                        <h3>{link}{item.jnode && <span className="incf-japan-node"> *</span>}</h3>
                        <span className="detail">
                            <HashLink to={`/dbtools#jump${item.id}`}>{more}</HashLink>
                        </span>
                        {item.url === '' && <div>(unreleased)</div>}
                    </div>
                </li>;
            })}
        </ul>
        <p className="legend">INCF Japan Node Platform</p>
    </div>;
}

export default DbToolsTop;