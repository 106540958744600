import React from 'react';
import { Helmet } from 'react-helmet';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const publications = [
    {
        key: 'cdtdb',
        name: 'BrainTx (Former CDT-DB)',
        items: [
            {
                title: 'Cerebellar development transcriptome (CDT-DB): profiling of spatio-temporal gene expression during the postnatal development of mouse cerebellum.',
                author: 'Sato, A., Sekine, Y., Saruta, C., Nishibe, H., Morita, N., Sato, Y., Sadakata, T., Shinoda, Y., Kojima, T., and Furuichi, T.',
                description: 'Neural Networks 21:1056-1069. (2008)',
                pmid: '18603407',
            },
            {
                title: 'Deciphering the genetic blueprint of cerebellar development by the gene expression profiling informatics. Neural information processing.',
                author: 'Sato, A., Morita, N., Sadakata, T., Yoshikawa, F., Shiraishi-Yamaguchi, Y., Huang, JH., Shoji, S., Tomomura, M., Sato, Y., Suga, E., Sekine, Y., Kitamura, A., Shibata, Y., Furuichi, T.',
                description: 'Lecture Notes in Computer Science 3316:880-884. (2004) Springer-Verlag, Berlin, Heidelberger, Germany',
                pmid: '',
            },
            {
                title: 'Investigation of differentially expressed genes during the development of mouse cerebellum.',
                author: 'Kagami, Y., and Furuichi, T.',
                description: 'Brain Research Gene Expression Patterns 1:39-59. (2001)',
                pmid: '15018818',
            },
        ],
    },
    {
        key: 'eeg',
        name: 'Multichannel EEG data for BMI and Human Emotions',
        items: [
            {
                title: 'Tensor Decompositions for Feature Extraction and Classification of High Dimensional Datasets.',
                author: 'Phan A-H, Cichocki A.',
                description: 'Nonlinear Theory and Its Applications, IEICE, 1(1), 37-68 (2010).',
                pmid: '',

            },
            {
                title: 'Generalizing the column-row matrix decomposition to multi-way arrays.',
                author: 'Caiafa C, Cichocki A.',
                description: 'Linear Algebra and its Applications, 433 (3), 557-573 (2010).',
                pmid: '',
            },
            {
                title: 'Extended HALS Algorithm for Nonnegative Tucker Decomposition and its Applications for Multi-Way Analysis and Classification.',
                author: 'Phan A-H, Cichocki A.',
                description: 'Neurocomputing 2011 (in press).',
                pmid: '',
            },
            {
                title: 'Fast Local Algorithms for Large Scale Nonnegative Matrix and Tensor Factorizations.',
                author: 'Cichocki A, Phan A-H.',
                description: 'IEICE Trans. Fundamentals E92-A(3), 708-721 (invited paper) (2009).',
                pmid: '',
            },
            {
                title: 'Nonnegative Matrix and Tensor Factorizations: Applications to Exploratory Multi-way Data Analysis,',
                author: 'Cichock A., Zdunek R.. Phan, A.H. and Amari: S.',
                description: 'John Wiley (2009), ISBN: 978-0-470-74666-0,',
                pmid: '',
            },
        ]
    }
];


const Publication: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Related Publications[/en][ja]関連論文[/ja]', lang);
    return <div className="publication">
        <Helmet><title>{title} - {Config.SITE_TITLE}</title></Helmet>
        <section>
            <h2>{title}</h2>
            {publications.map(publication => <section key={publication.key}>
                <h3 className={`name ${publication.key}`}>{publication.name}</h3>
                {publication.items.map(item => <article key={item.title}>
                    <h4 className="title">{item.title}</h4>
                    <p className="author">{item.author}</p>
                    <p className="description">{item.description}</p>
                    {item.pmid !== '' && <p className="pmid"><a href={`https://www.ncbi.nlm.nih.gov/pubmed/${item.pmid}`} target="_blank" rel="noopener noreferrer">PMID: {item.pmid}</a></p>}
                </article>)}
            </section>)}
        </section>
    </div>;
}

export default Publication;