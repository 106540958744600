import React from 'react';
import { Helmet } from 'react-helmet';
import Config, { MultiLang } from '../config';
import Functions from '../functions';

interface Props {
    lang: MultiLang;
}

const outlineTitle = {
    en: 'Outline',
    ja: '概要'
}
const outlineContent = {
    en: 'The BSI-NI (Neuroinformatics) Project was pursued in FY2007-FY 2017 to create a database covering research achievements of RIKEN Brain Science Institute (BSI) to promote information sharing within its organization and with the public domain. Developments by BSI laboratories were supported by the BSI Director\'s Fund.Operation of the project and services were supported by BSI- NI committee and Neuroinformatics Japan Center at RIKEN BSI.Since April 2018, this website is managed by Neuroinformatics Unit, RIKEN Center for Brain Science by aiming the sustainable accessibility of the database. Some of contents are continuously updated by labs at RIKEN Center for Brain Science.',
    ja: 'BSI-NI(Neuroinformatics)プロジェクトは、理化学研究所脳科学総合研究センター(1997年度-2017年度）の研究成果をデータベース化し公開するために設置されました。脳科学総合研究センター(BSI)では、BSI内外において情報共有を進め、生みだされた膨大かつ高品質なオリジナルデータをデータベースに構築・公開・運用する為に、BSIの予算(BSI-NIファンド)を活用してBSI-NIプロジェクト(2007年度-2017年度)を推進し情報公開に努めた成果です。当サイトはBSI-NI委員会とBSI-NI事務局である神経情報基盤センターによって運用されてきました。 2018年度の脳神経科学研究センターの開始に伴いBSI-NIプロジェクトは終了しましたが、本サイトは引き続き同センター内神経情報基盤開発ユニットが管理運用致します。コンテンツの一部は脳神経科学研究センターのラボにより今後も更新されます。引き続きご利用下さい。'
};

const projectMembers = {
    en: [
        { name: 'Tomoki Fukai, Ph.D.', lab: 'Laboratory Head, Lab. for Neural Circuit Theory' },
        { name: 'Atsushi Iriki, D.D.S., Ph.D.', lab: 'Laboratory Head, Lab. for Symbolic Cognitive Development' },
        { name: 'Keiichi Kitajo, Ph.D.', lab: 'Deputy Laboratory Head, Advanced Brain Signal Processing\nUnit Leader, Rhythm-based Brain Information Processing Unit, BTCC' },
        { name: 'Hajime Hirase, Ph.D.', lab: 'Laboratory Head, Neuron-Glia Circuitry' },
        { name: 'Taro Toyoizumi, Ph.D.', lab: 'Laboratory Head, Neural Computation and Adaptation' },
        { name: 'Shiro Usui, Ph.D.', lab: 'Senior Advisor, Neuroinformatics Japan Center' },
        { name: '◎Yoko Yamaguchi, Ph.D.', lab: 'Director, Neuroinformatics Japan Center' },
        { name: 'Motohide Yokota', lab: 'Director, Brain Science Planning Office' },
    ],
    ja: [
        { name: '深井　朋樹', lab: '脳回路機能理論研究チーム　チームリーダー' },
        { name: '入來　篤史', lab: '象徴概念発達研究チーム　チームリーダー' },
        { name: '北城　圭一', lab: '脳信号処理研究チーム　副チームリーダー\n脳リズム情報処理連携ユニット, BTCC　ユニットリーダー' },
        { name: '平瀬　肇', lab: '神経グリア回路研究チーム　チームリーダー' },
        { name: '豊泉　太郎', lab: '神経適応理論研究チーム　チームリーダー' },
        { name: '臼井　支朗', lab: '神経情報基盤センター　特別顧問' },
        { name: '◎山口　陽子', lab: '神経情報基盤センター　センター長' },
        { name: '横田　元秀', lab: '脳科学研究推進室　室長' },
    ],
};

const projectOffice = {
    en: [
        { name: 'Itsuko Ishii', lab: 'Technical Staff , Neuroinformatics Japan Center' },
        { name: 'Tazu Aoki', lab: 'Brain Science Planning Office' },
    ],
    ja: [
        { name: '石居　伊都子', lab: '神経情報基盤センター　テクニカルスタッフ' },
        { name: '青木　田鶴', lab: '脳科学研究推進室' },
    ],
};

const About: React.FC<Props> = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]About BSI-NI[/en][ja]プロジェクト概要[/ja]', lang);
    const fy2017 = Functions.mlang('[en]As of FY2017[/en][ja]2017年度[/ja]', lang);
    return <div className="about">
        <Helmet><title>{title} - {Config.SITE_TITLE}</title></Helmet>
        <section>
            <h2>{Functions.mlang('[en]Project Summary[/en][ja]プロジェクト概要[/ja]', lang)}</h2>
            <section>
                <h3>{outlineTitle[lang]}</h3>
                <p>{outlineContent[lang]}</p>
            </section>
        </section>
        <section>
            <h2>{Functions.mlang('[en]Organization (until FY2017)[/en][ja]運営組織 (2017年度まで)[/ja]', lang)}</h2>
            <section>
                <h3>{Functions.mlang('[en]BSI-NI Committee Member List[/en][ja]BSI-NI委員会メンバー表[/ja]', lang)}</h3>
                <table>
                    <caption className="date">{fy2017}</caption>
                    <tbody>
                        {projectMembers[lang].map(member => {
                            const regex = /(\n)/g;
                            return <tr key={member.name}><td>{member.name}</td><td>{member.lab.split(regex).map((line, idx) => {
                                if (line.match(regex)) {
                                    return <br key={idx} />;
                                } else {
                                    return line;
                                }
                            })}</td></tr>;
                        })}
                    </tbody>
                </table>
                <p>{Functions.mlang('◎ [en]acting Chairman[/en][ja]は委員長代行[/ja]', lang)}</p>
            </section>
            <section>
                <h3>{Functions.mlang('[en]BSI-NI Project Secretariat[/en][ja]BSI-NIプロジェクト事務局[/ja]', lang)}</h3>
                <table>
                    <caption className="date">{fy2017}</caption>
                    <tbody>
                        {projectOffice[lang].map(member => {
                            return <tr key={member.name}><td>{member.name}</td><td>{member.lab}</td></tr>;
                        })}
                    </tbody>
                </table>
            </section>
        </section>
    </div>;
}

export default About;