import React from 'react';
import { MultiLang } from '../config';
import bannerJNODE from './assets/theme/images/banner_incf-japan-node.png';
import bannerINCF from './assets/theme/images/banner_incf.png';
import bannerNIU from './assets/theme/images/banner_riken-cbs-niu.png';
import bannerCBS from './assets/theme/images/banner_riken-cbs.png';
import bannerRIKEN from './assets/theme/images/banner_riken.png';
import bannerXOONIPS from './assets/theme/images/banner_xoonips.png';


interface Props {
    lang: MultiLang;
}

const links = [
    {title: 'RIKEN', url: 'http://www.riken.jp/', image: bannerRIKEN},
    {title: 'RIKEN Center for Brain Science', url: 'https://cbs.riken.jp/', image: bannerCBS},
    {title: 'Neuroinformatics Unit, RIKEN Center for Brain Science', url: 'https://www.ni.riken.jp/', image: bannerNIU},
    {title: 'INCF Japan Node', url: 'https://www.neuroinf.jp/', image: bannerJNODE},
    {title: 'INCF', url: 'https://www.incf.org/', image: bannerINCF},
    {title: 'XooNIps Offical Site', url: 'https://xoonips.osdn.jp/', image: bannerXOONIPS},
]

const Footer: React.FC<Props> = (props: Props) => {
    return (
        <footer id="footer" className="clearfix">
            <div className="copyright">
                &copy; 2007-2018 RIKEN Brain Science Institute,<br />
                &copy; 2018- RIKEN Center for Brain Science, All rights reserved.
            </div>
            <div className="links">
                {links.map((item)=> {
                    return <a key={item.title} href={item.url} title={item.title} target="_blank" rel="noopener noreferrer"><img src={item.image} alt={item.title} /></a>
                })}
            </div>
        </footer>
    );
}

export default Footer;